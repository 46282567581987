import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/map/mngYusaku.jpg';
import ishizaka from '../images/map/2021-3-新井貴久.jpg';
// import ishizakaImg from '../images/map/ishizakaImg.jpg';
// import ishizakaTop from '../images/ob/ob_ishizaka_top.jpg';
// import * as ROUTES from '../src/constants/routes';

class AraiPage extends React.Component {

  render() {
    return (
      <Fragment>
        <title>地方部員インタビュー　新井貴久|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">地方部員インタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'black', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">1年 吉田</h4>
          <div className="mng-text">
          本日はインタビューに応じてくださり、ありがとうございます。國學院久我山高校一年生の吉田優作です。よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'black'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <h4 className="vertical-timeline-element-subtitle">3年 新井貴久</h4>
          <div className="ob-text">
          よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          まず初めに自己紹介をお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい。國學院久我山高校3年の新井貴久です。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。それでは早速インタビューを始めさせていただきます。國學院久我山高校への入学理由について教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          國學院久我山高校はバスケと学業のレベルが高く、自分もそのレベルの高い高校に身を置き、成長したいと思い、進学を決めました！
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。國學院久我山高校の特徴は、学業も部活も一生懸命なところですよね！次に、國學院久我山高校バスケットボール部に、入部してから楽しかったことについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          まずは達成感ですね！苦楽をチームの仲間と共にして、合宿の時が一番きつかったですが、それを乗り越えた時はとても楽しかったです！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。
合宿で苦楽を仲間と共にすることは、とてもいいことですよね！自分はまだ入学したばかりなので、これから仲間といろんな苦労を乗り越えていきたいと思います！続いて、久我山高校バスケットボール部の良いところについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい。まず練習を真剣にできることです。みんな「勝ちたい！」という気持ちが強いので、とても充実した練習ができます。練習外では、先輩も後輩もみんな仲良いです。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます！みんな本当に仲が良いですよね！ちなみに、久我山高校バスケットボール部のプレーでの強みはどのような所でしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          はい。久我山の強みは何と言ってもインサイドです。それに加えて今年は、インサイドだけでなく、3ポイントシュートも入るプレーヤーも多いのでオフェンスの選択肢が多いです。また、スローガンの堅守考攻のように堅い守りも久我山の強みです。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          今年はインサイドだけでなく3ポイントがとてもよく入っている印象です。ありがとうございます。ちなみに、新井さんは、神奈川から通われてるとのことですが、なぜ神奈川の高校でなく東京にある國學院久我山高校を選んだのですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          先程も話した通り、学業と部活の両立ができる点が大きいです。また、その先の大学進学も考えた時に、非常に魅力的な学校だと思い、久我山高校で文武両道を実践したいと思いました！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。神奈川から通われる上で心配であったことについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          初めての電車通学で人混みが多くてびっくりしました！（笑）あとちゃんと通えるか、など最初は心配でしたね！なつかしいです。（笑）
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          そうなんですね！（笑）電車の中では、どのようなことをされていますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          基本は、単語帳を開いてます。またテスト前は、暗記物がメインです。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          長い電車通学では、時間の有効活用が大切ですよね。では続いて、今年のチームの目標と個人の目標をお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          個人の目標は、コミュニケーションの要となって、チーム内のコミュニケーションを活性化させることです。チームの目標は、東京都優勝とインターハイベスト8です。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          最近は、チームで必要な声が出てきていて、チームの雰囲気が良いと思います！では、最後に國學院久我山高校バスケットボール部に入部しようと考えてる中学生にメッセージをお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={ishizaka} />}
        >
          <div className="ob-text">
          久我山では勉強も部活もレベルが高いので、それをしっかり強みにして、部活や勉強を言い訳せずに両方両立して頑張って欲しいと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          本日はインタビューに応じて下さり、ありがとうございました！
          </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <AraiPage />
  </Layout>
);
